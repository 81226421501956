export const FETCH_SUCCESS = "PUBLIC_EVENT/FETCH_SUCCESS";
export const FETCH_REQUEST = "PUBLIC_EVENT/FETCH_REQUEST";
export const FETCH_FAILED = "PUBLIC_EVENT/FETCH_FAILED";

export const TABLE_UPDATE = "PUBLIC_EVENT/TABLE_UPDATE";
export const SETTINGS_SUCCESS = "PUBLIC_EVENT/SETTINGS_SUCCESS";
export const SETTINGS_FAILED = "PUBLIC_EVENT/SETTINGS_FAILED";
export const SETTINGS_REQUEST = "PUBLIC_EVENT/SETTINGS_REQUEST";
export const SCROLL = "PUBLIC_EVENT/SCROLL";

const types = {
    FETCH_SUCCESS,
    FETCH_FAILED: "PUBLIC_EVENT/FETCH_FAILED",
    FETCH_REQUEST: "PUBLIC_EVENT/FETCH_REQUEST",

    GET_FAILED: "PUBLIC_EVENT/GET_FAILED",
    GET_REQUEST: "PUBLIC_EVENT/GET_REQUEST",
    GET_SUCCESS: "PUBLIC_EVENT/GET_SUCCESS",

    SETTINGS_FAILED,
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,

    SCROLL,

    TRANSLATE_FAILED: "PUBLIC_EVENT/TRANSLATE_FAILED",
    TRANSLATE_REQUEST: "PUBLIC_EVENT/TRANSLATE_REQUEST",
    TRANSLATE_SUCCESS: "PUBLIC_EVENT/TRANSLATE_SUCCESS",

    TABLE_UPDATE,
};

export default types;
