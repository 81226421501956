import web from "./api";
import appApi from "../App/api";
import types from "./types";
import { request } from "api/apiSaga";
import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import { publicEventSettings } from "./actions";
import { loadWebModules } from "moduleSaga";
import { isEmpty } from "lodash";
import {
    APP_INIT,
    APP_LOADED,
    APP_TRANSLATE_REQUEST,
    LOCALE_REQUEST,
} from "types";
import { changeLocale } from "app/App/actions";
import rootStore from "rootStore";
import { tableUpdate } from "helpers/reducers";

function* initApp() {
    const uuid = window.location.pathname.match(
        /\/tickets\/([A-Za-z0-9-]+)/
    )?.[1];
    if (uuid) {
        yield call(loadWebModules);
        // yield put(appTranslate());
        yield put({
            type: APP_LOADED,
            app: "PublicEvent",
            uuid,
        });
    }
}
function* fetch(action: AnyAction) {
    yield call(request, web.fetch, action);
}

function* get(action: AnyAction) {
    yield call(request, web.get, action);
}

function* settings(action: AnyAction) {
    yield call(request, web.settings, action);
}

function* translate(action: AnyAction): any {
    const response = yield call(request, appApi.translate, action);
    if (response.status === 200) {
        (window as any).translate = response.data.data;
        document.documentElement.lang = response.data.locale;
    }
}

function* locale(action: AnyAction): any {
    yield call(translate, changeLocale(action.axiosConfig.config));
    yield call(updateSettings, action);
}

function* updateSettings(action: AnyAction): any {
    const state = rootStore.getState().web;
    const { loaded } = rootStore.getState().app;
    if (!loaded) return;
    const { filters, search } = tableUpdate(state, action);

    const params: any = {};
    if (!isEmpty(filters)) {
        params._filters = filters;
    }
    if (search) {
        params._search = search;
    }
    if (
        action.type === "LOCALE_REQUEST" ||
        action.action === "filter" ||
        action.action === "clearFilters" ||
        action.action === "search"
    ) {
        yield put(
            publicEventSettings(state.uuid, {
                params,
            })
        );
    }
}
export const PublicEventSagas = [
    takeLatest(APP_INIT, initApp),
    takeLatest(types.TABLE_UPDATE, updateSettings),
    takeLatest(types.FETCH_REQUEST, fetch),
    takeLatest(types.GET_REQUEST, get),
    takeLatest(LOCALE_REQUEST, locale),
    takeLatest(types.SETTINGS_REQUEST, settings),
    takeLatest(APP_TRANSLATE_REQUEST, translate),
];
