import { registerPublicRoutings } from "rootRouting";
import { IConfig, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const url = "/tickets";
export const uuid = window.location.pathname.match(
    /\/tickets\/([A-Za-z0-9-]+)/
)?.[1];

const getConfig = () => {
    const config: IConfig = {
        permissions: {
            create: true,
            delete: true,
            read: true,
            update: true,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];
    routing.push({
        path: `${url}/${uuid}`,
        element: <AsyncComponent path="PublicEvent/pages/PublicEventIndexPage" />,
    });

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerPublicRoutings(routing);
    }

    return config;
};

export default getConfig;
