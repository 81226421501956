import { IPublicEventPagination } from "./interfaces";
import {
    FETCH_FAILED,
    FETCH_REQUEST,
    FETCH_SUCCESS,
    SCROLL,
    SETTINGS_FAILED,
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,
    TABLE_UPDATE,
} from "./types";

import { tableResponse, tableUpdate } from "helpers/reducers";
import { AnyAction } from "redux";
import { APP_LOADED } from "types";
import { IGPTable } from "ui/Table/Table";
interface IPublicEvent
    extends Omit<
        IGPTable,
        "available_columns" | "filterSettings" | "pagination"
    > {
    uuid: string;
    filterSettings: {
        open: boolean;
        total: number;
    };
    expand: "";
    active: boolean;
    pagination: IPublicEventPagination;
    mobileFilters: boolean;
    name: string;
    show_prices: boolean;
    show_stock: boolean;
    show_rating: boolean;
    settingsLoader: boolean;
    scroll: boolean;
    itemsLoaded: boolean;
    products: any;
    status: string;
}

function PublicEvent(
    state: IPublicEvent = {
        id: "PublicEvent",
        active: false,
        filters: {},
        filterData: [],
        filterSettings: {
            open: false,
            total: 0,
        },
        columns: [],
        pagination: {
            page: 1,
            per_page: 18,
            isLastPage: true,
            total: 0,
            last_page: 1,
        },
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        selectionActions: null,
        sort: { "restaurant_product.stock": "desc" },
        with: [],
        uuid: "",
        expand: "",
        mobileFilters: false,
        name: "",
        show_prices: false,
        show_stock: false,
        show_rating: false,
        settingsLoader: true,
        scroll: false,
        itemsLoaded: false,
        products: null,
        elasticSearch: true,
        status: "",
    },
    action: AnyAction
) {
    switch (action.type) {
        case FETCH_REQUEST:
            return {
                ...state,
                itemsLoaded: false,
            };
        case FETCH_FAILED:
            return {
                ...state,
                itemsLoaded: true,
            };
        case FETCH_SUCCESS:
            return {
                ...tableResponse(state, action),
                pagination: {
                    ...tableResponse(state, action).pagination,
                    last_page: action.response.data.meta.last_page,
                },
                itemsLoaded: true,
                products: action.response.data.data,
            };


        case TABLE_UPDATE:
            return {
                ...tableUpdate(state, action),
                scroll: false,
                products: null,
            };

        case SETTINGS_REQUEST:
            return { ...state, settingsLoader: true };
        case SETTINGS_FAILED:
            return { ...state, settingsLoader: false };
        case SETTINGS_SUCCESS:
            return {
                ...state,
                ...action.response.data,
                filterData: action.response.data.filters_data.filter(
                    (item: any) => item.values?.length > 0 || !item.values
                ),
                active: action.response.data.active,
                settingsLoader: false,
            };
        case APP_LOADED:
            return {
                ...state,
                uuid: action.uuid,
                status: action.status|| "",
            };
        case SCROLL:
            return {
                ...state,

                scroll: true,
            };
        default:
            return state;
    }
}

export default PublicEvent;
