import { Navigate, useRoutes } from "react-router-dom";
import NotFound from "app/App/pages/NotFound";

let routings = [];
let publicRoutings = [];

export function registerRoutings(data) {
    routings.push(...data);
}
export function registerPublicRoutings(data) {
    publicRoutings.push(...data);
}

export const AppRouting = () => {
    const navigatePath =
        routings.find((item) => item.path === "/event")?.path ||
        routings.find((item) => item.path.split("/").length === 2)?.path;

    return useRoutes([
        ...routings,
        { path: "/", element: <Navigate to={navigatePath} replace={true} /> },
        { path: "*", element: <NotFound /> },
    ]);
};

export const PublickEventRooting = () => {
    return useRoutes([...publicRoutings, { path: "*", element: <NotFound /> }]);
};

export default AppRouting;
