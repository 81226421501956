import {
    APP_INIT,
    APP_LOADED,
    APP_SETTINGS_SUCCESS,
    MODAL_HIDE,
    MODAL_SHOW,
    SNACKBAR_HIDE,
    SNACKBAR_SHOW,
    SIDE_NAV_TYPE,
    LOCALE_REQUEST,
    LOCALE_SUCCESS,
} from "types";

import { AnyAction } from "redux";

import { rs } from "helpers/reducers";

interface IState {
    loaded: boolean;
    locationId: number | null;
    locations: any;
    modals: Array<{
        id: string;
    }>;
    organisationsWithActiveApp: any;
    request: any;
    settings: any;
    snackbar: any;
    sideNavType: string;
    app: string;
}
function general(
    state: IState = {
        loaded: false,
        locationId: null,
        locations: {},
        modals: [],
        organisationsWithActiveApp: [],
        request: {},
        settings: {},
        snackbar: null,
        sideNavType: "main",
        app: "App",
    },
    action = {} as AnyAction
) {
    switch (action.type) {
        case APP_INIT:
            return rs.init(state);

        case APP_LOADED:
            return {
                ...state,
                app: action.app,
                loaded: true,
                locationId: action.locationId,
                locations: action.locations,
                organisationsWithActiveApp: action.organisationsWithActiveApp,
            };

        case APP_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.response.data,
            };

        case MODAL_HIDE:
            return {
                ...state,
                modals: state.modals.filter((modal) => modal.id !== action.id),
            };

        case MODAL_SHOW:
            return {
                ...state,
                modals: [
                    ...state.modals,
                    {
                        id: action.id,
                        props: action.props,
                    },
                ],
            };

        case SNACKBAR_HIDE:
            return {
                ...state,
                snackbar: null,
            };
        case SNACKBAR_SHOW:
            return {
                ...state,
                snackbar: action.data,
            };
        case SIDE_NAV_TYPE:
            return {
                ...state,
                sideNavType: action.data,
            };
        case LOCALE_REQUEST:
            return {
                ...state,
                loaded: false,
            };
        case LOCALE_SUCCESS:
            return {
                ...state,
                loaded: true,
            };
        default:
            return state;
    }
}

export default general;
