import types from "./types";
import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const publicEventFetch = (id: number, config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "publicEventFetch",
        {
            url: {
                id,
            },
            config,
        }
    );

export const publicEventGet = (id: number, config?: AxiosRequestConfig) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "publicEventGet",
        {
            url: {
                id,
            },
            config,
        }
    );

export const publicEventSettings = (
    id: string,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.SETTINGS_REQUEST,
        types.SETTINGS_FAILED,
        types.SETTINGS_SUCCESS,
        "publicEventSettings",
        {
            url: {
                id,
            },
            config,
        },
        onResponse
    );

export const publicEventTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});
